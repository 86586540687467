@tailwind base;
@tailwind components;
@tailwind utilities;
 

 .wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: gold;
}

.wrapperItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background-color: coral; 
    border: solid blue 1px;

}

.itemCon {
  display: flex;
  width: 15%;
  height: 7vh;
  background-color: pink;
  border: solid red 1px;
}

.itemCon p {
  margin: auto;
}